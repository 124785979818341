globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"f2129c6657c80597751b9006d45c225de1f5b5a4"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://7092059d9ffc460da7586c77466cefd4@o458855.ingest.sentry.io/5457109",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  integrations: [],

  networkDetailAllowUrls: [
    "https://apollo.joinladder.com",
    "https://apollo.dev.joinladder.com",
    "https://dev.joinladder.com",
    "https://joinladder.com",
    "https://rocky-api.dev.ltdev.io",
    "https://rocky-api.prod.ltdev.io",
  ],

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
